.main__nav__btn,
.submit__btn {
  .v-btn__content {
    color: $color-white;
    text-transform: none !important;
    cursor: pointer !important;
    @include font-size(14);
    font-weight: $fw-bold;
  }
  background-color: $color-primary !important;

  &--admin {
    background-color: $color-secondary !important;
  }
  &--form {
    position: relative;
    float: right;
  }
}
.active .main__nav__btn {
  background-color: $color-white !important;
  color: $color-primary;
  .v-btn__content {
    color: $color-primary;
    @include font-size(14);
    font-weight: $fw-bold;
  }

  &--admin {
    background-color: $color-white !important;
    .v-btn__content {
      color: $color-secondary !important;
      font-weight: $fw-bold;
    }
  }
}

.main__nav__btn:disabled {
  .v-btn__content {
    color: $color-disabled !important;
  }
}


// SFG
button {
    // min-width: 64px;
    // width: 100%;
    // display: inline-block;
    // border: none;
    // // padding: 1rem;
    // margin: 0;
    // height: 48px;
    // text-decoration: none;
    // background-color: var(--bg-buttons);
    // color: $bgDarkGrey;
    // cursor: pointer;
    // text-align: center;
    // // transition: background .2s ease-in-out,
    // //             transform .1s ease;
    // transition: all .1s;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // font-family: $body-font-family;
    // font-weight: 500;
    // font-size: map-get($font-sizes, plarge);

    // background: var(--bg-color-alpha);
    color: var(--txt-color-inv);
    margin: 0;
    border: none;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 0.875rem;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.1s ease-in-out;
    transition-property: color, background-color, border-color;
    cursor: pointer;


}

button:focus {
    outline: 1px solid $wealgoBlue;
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
}

button.black {
  color: $bgLightGrey;

  &:hover {
    background: $bgDarkGreyAlpha;
    color: $wealgoBlue;
  }

}
button.alpha {
  background: $wealgoBlueAlpha;
  color: $bgDarkGrey;
}
button.go {
  padding: 0 12px;
}


// new button class
.nbtn {
  color: $bgLightGrey;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  // height: 48px;
  // line-height: 48px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.red {
  background-color: $wealgoRed;
}
.blue {
  background-color: $wealgoBlue;
}
.grey {
  background-color: $bgLightGreyAlpha;
  color: $wealgoRed;
  // background-color: black;
  // opacity: .4;
}

.enter {
  // background-image: -webkit-radial-gradient(calc(100% - 28px) calc(100% + 0px), circle farthest-corner, transparent 0, transparent 34px, $wealgoRed 35px);
  max-width: 520px;
  margin: 0 auto;
  padding: 2rem;
  color: $wealgoBlack;
}

// .again {
//   background-image: -webkit-radial-gradient(calc(100% - 28px) calc(100% + 0px), circle farthest-corner, transparent 0, transparent 34px, $wealgoBlue 35px);
// }

.fab {
  width: 56px;
  height: 56px;
  line-height: 56px;
  color: $bgLightGrey;
  cursor: pointer;
  text-align: center;
  border-radius: 100%;
}

.minifab {
  width: 40px;
  height: 40px;
  color: $bgLightGrey;
  cursor: pointer;
  text-align: center;
  border-radius: 100%;
}
