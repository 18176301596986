

// mask editor slides dialogues
.slides_container {
  width: 100%;
  position: absolute;
  // top: 18vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  // background-color: var(--bg-color-rgba);
  z-index: 1;
  // border: 1px solid $wealgoBlueAlpha;

  .quote {
    font-size: map-get($font-sizes, h2);
    line-height: map-get($line-heights, h1);
  }

  .quote_author {
    font-size: map-get($font-sizes, plarge);
    line-height: map-get($line-heights, h3);
  }


  .slides {
    height: 50vh;
  }

  .slides_nav {
    height: 32px;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }

  .slides_nav a {
    display: inline-flex;
    width: 24px;
    height: 24px;
    background-color: $wealgoBlueAlpha;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 12px;
    position: relative;
    font-size: map-get($font-sizes, xs);
    color: $bgLightGrey;
  }
  .slides_nav  a:active {
    top: 1px;
  }
  .slides_nav a:focus {
    background: $wealgoBlue;
  }
}


.slides_slider {
  width: 100%;
  text-align: center;
  overflow: hidden;

  .slides {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  .slides::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .slides::-webkit-scrollbar-thumb {
    background: var(----bg-scrollbar);
    border-radius: 10px;
  }
  .slides::-webkit-scrollbar-track {
    background: transparent;
  }
  .slides > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    // height: 220px;
    // margin-right: 50px;
    // border-radius: 10px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;


    .slide_inner {
      display: grid;
      // grid-template-rows: 80px 1fr 1fr;
      grid-template-rows: auto;
      // height: 100%;
      align-items: center;
      padding: 0 12px;

    }
  }
}
