//Colors
$color-white: #ffffff;
$color-white-offset: #f4f4f4;
$color-black: #000000;
$color-black-offset: #161625;
$color-primary: #00a0ff;
$color-secondary: #0868a7;
$color-highlight: #ee220c;
$color-warning: #ff5252;
$color-disabled: #aaa;

// SFG colors added
$bgLightGrey: #f5f5f5;
$bgLightGreyAlpha: rgba(245,245,245,.94);
$bgDarkGrey: #161625;
$bgDarkGreyAlpha: rgba(22,22,37,.9);
$wealgoBlue: #00a0ff;
$wealgoBlueAlpha: rgba(0, 160, 255, 0.6);
$wealgoBlueLowLight: #196aa6;
$red: #ff0000;

$wealgoRed: #ff0000;
$wealgoOrange: #ff8d00;
$wealgoYellow: #ffff00;
$wealgoGreen: #00ff00;
$wealgoBlue: #00a0ff;
$wealgoPurple: #c700c7;
$wealgoBrown: #932e00;
$wealgoGrey: #b3b3b3;
$wealgoWhite: #ffffff;
$wealgoBlack: #000000;


// new Colors
$BgColorLight: #f5f5f5;
$BgColorLightAlpha: rgba(245,245,245,.86);
$BgColorDark: #161625;
$BgColorDarkAlpha: rgba(22,22,37,.86);
$textBgColorLight: rgba(0,0,0,.82);
$textBgColorDark: rgba(255,255,255,.82);
$borderBgColorLight: rgba(0,0,0,.1);
$borderBgColorDark: rgba(255,255,255,.1);
$BgColorBase: #666f76;
$BgColorBaseAlpha: rgba(102, 111, 118, .86);
