// font imported from google fonts in public/index.html
$font-custom: "Raleway";
$font-fallback: Helvetica, Arial, sans-serif;

// SFG adding font $wealgo == opensans goole
$font-wealgo: 'OpenSans-Light';


$fw-light: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-bold: 700;
$font-main: $font-wealgo, $font-custom, $font-fallback;
$body-font-family: $font-main;
$header-font-family: $font-main;
/* vuetify hack overwrite font family see setup.scss*/

$line-height: 1.25;

$font-sizes: (
  h1: 3rem,
  h2: 2.6rem,
  h3: 2rem,
  h4: 1.4rem,
  h5: 1.2rem,
  pxlarge: 1.66rem,
  plarge: 1.4rem,
  p: 1rem,
  psmall: .9rem,
  xs: .8rem
);

$line-heights: (
  h1: 3rem,
  h2: 2.6rem,
  h3: 2rem,
  h4: 1.4rem,
  h5: 1.2rem,
  plarge: 1.8rem,
  p: 1.4rem,
  psmall: 1.2rem
);

$font-sizes-lg: (
  h1: 4rem,
  h2: 3.6rem,
  h3: 2.8rem,
  h4: 1.6rem,
  h5: 1.4rem,
  plarge: 1.6rem,
  p: 1.4rem,
  psmall: 1.2rem,
  xs: 1rem
);

$line-heights-lg: (
  h1: 4rem,
  h2: 3.8rem,
  h3: 2.8rem,
  h4: 2.6rem,
  h5: 2.4rem,
  plarge: 2.6rem,
  p: 2.4rem,
  psmall: 2.4rem
);
