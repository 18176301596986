.hide {
  display: none !important;
}
.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}
.visible {
  visibility: visible;
}

.m {
    font-size: map-get($font-sizes, p);
}
.xl {
    font-size: map-get($font-sizes, pxlarge);
}
.l {
    font-size: map-get($font-sizes, plarge);
}
.smll {
    font-size: map-get($font-sizes, psmall);
}
.xs {
    font-size: map-get($font-sizes, xs);
}

a.break-all {
  word-break: break-all;
}

.fixed {
  position: fixed;
}
.bottom-0 {
  bottom: 0;
}
.invert {
  filter: invert(1);
}

.fright {
  float: right;
}

.pbottom {
  padding-bottom: $headerHeight;
}
.pbottom-xl {
  padding-bottom: $headerHeight * 2;
}

.ptop {
  padding-top: $headerHeight;
}

.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}


.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}
.underline {
  text-decoration: underline;
}

.pulse {
  animation: pulse 3s infinite;
}
@keyframes pulse {
  0%   { opacity:1; }
  36%  { opacity:0; }
  100% { opacity:1; }
}

.nopointer {
  pointer-events: none;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.white-space {
  white-space: pre-line;
}

.editable {
  cursor: context-menu;
}
