// Icons
.icon {
  width: 56px;
  height: 56px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  filter: var(--filter);
  -webkit-filter: var(--filter);
}

.icon-xs {
  cursor: pointer;
  filter: var(--filter);
  -webkit-filter: var(--filter);
}

.no-filter {
  filter: none;
  -webkit-filter: none;
}

.overlay { // generic button/icon overlay for hover
  opacity: .60;
  transition: all .2s;

  &:hover {
    opacity: .92;
  }
}

// top nav
.hamburger-icon {
  background-image: url('../images/hamburger.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

// .logo-icon {
//   background-image: url('../images/logo_xl.svg');
//   background-repeat: no-repeat;
//   background-position: center center;
//   margin-left: 8px;
// }

// side nav
.js-status-live {
  .live-icon {
    background-image: url('../images/live.svg');
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
      cursor: default;
    }
  }

  &.playback .live-icon {
    filter: hue-rotate(90deg) brightness(2);
    -webkit-filter: hue-rotate(90deg) brightness(2);
  }
}

.icon-info {
  background-image: url('../images/info.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-close {
  background-image: url('../images/close.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

// bottom nav
#js-mute .icon-mute { // switch microphone icon
  background-image: url('../images/microphone-active.svg');
  background-repeat: no-repeat;
  background-position: center center;
}
#js-mute.muted .icon-mute {
  background-image: url('../images/microphone-inactive.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.more-menu-icon {
  background-image: url('../images/more-menu.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-explore-rooms {
  background-image: url('../images/explore-rooms.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-renew-mask {
  background-image: url('../images/refresh.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-add {
  background-image: url('../images/add_circle.svg');
  background-repeat: no-repeat;
  background-position: center center;
}
.icon-delete {
  background-image: url('../images/trash.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-share-room {
  background-image: url('../images/share-room.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-start-room {
  background-image: url('../images/start-room.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

#fip-player .icon-fip-player {
  background-image: url('../images/fip-playing.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

#fip-player.muted .icon-fip-player {
  background-image: url('../images/fip-muted.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-room-settings {
  background-image: url('../images/room-settings.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-edit-avatar {
  background-image: url('../images/edit-avatar.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-chat {
  background-image: url('../images/chat.svg');
  background-repeat: no-repeat;
  background-position: center center;

  &.unread:after {
    content: "";
    width: 9px;
    height: 9px;
    border-radius: 50%;
    // border: 2px solid var(--txt-color-inv);
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 10px;
    background: $wealgoRed;
    filter: invert(1);
    opacity: 1;
  }

}

.icon-send-message {
  background-image: url('../images/send-message.svg');
  background-repeat: no-repeat;
  background-position: center center;
}


// wear-wealgo specific icons
.icon-open-editor {
  background-image: url('../images/edit-avatar.svg');
  background-repeat: no-repeat;
  background-position: center center;
}
.icon-close-editor {
  background-image: url('../images/close.svg');
  background-repeat: no-repeat;
  background-position: center center;
}
.icon-snap-avatar {
  background-image: url('../images/camera.svg');
  background-repeat: no-repeat;
  background-position: center center;
}
.icon-snap-again {
  background-image: url('../images/trash.svg');
  background-repeat: no-repeat;
  background-position: center center;
}
.icon-warning {
  background-image: url('../images/warning.svg');
  background-repeat: no-repeat;
  background-position: center center;
  filter: invert(1);
}
.icon-cart {
  background-image: url('../images/cart.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

// merch selector wear-wealgo
.merch_selectors_wrapper {
  .merch_selectors_toggle {
    background-image: url('../images/chevron-right.svg');
    background-repeat: no-repeat;
    background-position: center center;
  }

  &.show_selectors {
    .merch_selectors_toggle {
      background-image: url('../images/chevron-left.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

nav.icons-right {
  position: fixed;
  top: 56px;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
}


// GoLive (presenters only)
.golive {
  position: fixed;
  top: 112px;
  right: 20px;
  z-index: 10;
  background: var(--bg-color-inv);
  color: var(--txt-color-inv);
  width: auto;
  height: 38px;
  padding-left: 2rem;
  padding-right: 2rem;
  line-height: 38px;
  cursor: pointer;

    span:hover {
      cursor: pointer;
    }

  .golive-switch input {
    display:none;
  }

}



.theme-switch {
  display: inline-block;
  position: relative;
  line-height: 0;

  &:hover {
    cursor: pointer;
  }

}

.theme-switch input {
// .doors-switch input,
// .golive-switch input {
  display:none;
}

.theme-switch input + span {
  width: 24px;
  height: 24px;
  background-image: url('../images/nightmode_night_v2.svg');
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.theme-switch input:checked + span {
  width: 24px;
  height: 24px;
  background-image: url('../images/nightmode_day_v2.svg');
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

// .doors-switch input + span {
//   width: 24px;
//   height: 24px;
//   background-image: url('../images/doors_closed.png');
//   background-repeat: no-repeat;
//   background-size: contain;
//   display: inline-block;
//   margin-left: 6px;
// }
//
// .doors-switch input:checked + span {
//   width: 24px;
//   height: 24px;
//   background-image: url('../images/doors_open.png');
//   background-repeat: no-repeat;
//   background-size: contain;
//   display: inline-block;
// }


// animated logo
// 3 status: waiting, playback, live (default)
li.logo-anim {
  margin-left: 8px;
  cursor: default;

  svg circle {
    filter: invert(1);
    -webkit-filter: invert(1);
  }

  svg path {
    pointer-events: all;
  }

  &.playback {

    svg circle {
      filter: hue-rotate(330deg) brightness(2);
      -webkit-filter: hue-rotate(330deg) brightness(2);
    }

  }

  &.waiting {

    svg circle {
      filter: hue-rotate(36deg) brightness(2);
      -webkit-filter: hue-rotate(36deg) brightness(2);
    }

  }

}


// terms of use
.terms {
  position: fixed;
  bottom: 2px;
  left: 8px;
  font-size: x-small;

  &:hover {
    cursor: pointer;
  }
}
