// SFG: media queries
@mixin mobile-smll {
  @media ( max-width: $breakpoint-xs ) { // <375px iphone 5/se
    @content;
  }
}

@mixin mobile {
  @media ( min-width: $breakpoint-xs ) { // >375px iphone 6/7/8
    @content;
  }
}
@mixin mobile-xl {
  @media ( min-width: $breakpoint-sm ) { // >375px iphone 6/7/8
    @content;
  }
}

@mixin tablet {
  @media ( min-width: $breakpoint-md ) { // 768px ipad
    @content;
  }
}

@mixin desktop {
  @media ( min-width: $breakpoint-lg ) { // 1024px
    @content;
  }
}

@mixin desktop-hd {
  @media ( min-width: $breakpoint-xl ) { // 1280px
    @content;
  }
}

@mixin landscape {
  @media ( orientation: landscape ) { // landscape
    @content;
  }
}
