// SFG: adding mask editor toggle (show/hide in powder room)

#mask-editor {
  pointer-events: none;
  transition: all 0.2s;

  // SFG: new editor tools switch
  .mask-editor__tools > div,
  .mask-editor__colors,
  .hintsButton {
    visibility: hidden;
  }

  .mask-editor__colors {
    position: absolute;
    bottom: 0.5rem;
    width: calc(100% - 4rem);
    @include mobile-xl {
      bottom: 2rem;
    }
  }


  &.active .mask-editor__tools > div,
  &.active .mask-editor__colors,
  &.active .hintsButton {
    visibility: visible;
  }

  &.active .expand-more {
    display: none;
  }
  &.active .expand-less {
    display: block;
  }

  .color-list {
    display: grid;
    align-items: center;
    min-height: 42px;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;

    @include mobile-xl {
      grid-template-columns: repeat(11, 1fr);
    }

    &__item {
      width: 100%;
      height: 42px;
      cursor: pointer;
      transition: all 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &--active {
        transform: scale(1.2);
        //cursor: default;
      }
      &:hover {
        transform: scale(1.4);
      }
    }
    &__item__outer {
      position: relative;
      width: 22px;
      height: 22px;
      max-height: 42px;
      max-width: 42px;
      border-radius: 50%;
      border: 1px solid #000;
      @include tablet {
        width: 26px;
        height: 26px;
      }
    }
    .border-white {
      border: 1px solid $wealgoWhite;
    }

    &__item__inner {
      width: 10px;
      height: 20px;
      max-height: 42px;
      max-width: 42px;
      border-radius:10px 0 0 10px;
      position: relative;
      left: 0px;
      border-right: 1px solid black;
      z-index: 50;
      @include tablet {
        width: 12px;
        height: 24px;
        border-radius:12px 0 0 12px;
      }

      &--full {
        width: 20px;
        height: 20px;
        left: 0;
        border-radius: 50%;
        border-left: none;
        @include tablet {
          width: 24px;
          height: 24px;

        }
      }
    }

    &__item--glitter {
      width: 10px;
      height: 20px;
      max-height: 42px;
      max-width: 21px;
      position: absolute;
      z-index: 100;
      left: 10px;
      clip-path: path('M10,0 a1,1 0 0,0 0,20 z');
      transform: rotate(180deg);
      @include tablet {
        left: 3px;
        width: 24px;
        height: 24px;
        clip-path: path('M12,0 a1,1 0 0,0 0,24 z');
      }
      .glit {
        position: absolute;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        pointer-events: none;
      }
    }
    &__item--red {
      background: $wealgoRed;
    }
    &__item--orange {
      background: $wealgoOrange;
    }
    &__item--yellow {
      background: $wealgoYellow;
    }
    &__item--green {
      background: $wealgoGreen;
    }
    &__item--blue {
      background: $wealgoBlue;
    }
    &__item--purple {
      background: $wealgoPurple;
    }
    &__item--brown {
      background: $wealgoBrown;
    }
    &__item--grey {
      background: $wealgoGrey;
    }
    &__item--white {
      background: $wealgoWhite;
    }
    &__item--black {
      background: $wealgoBlack;
      border-right: 1px solid $wealgoWhite;

    }
    &__item--red {
      background: $wealgoRed;
    }
    &__item--transparent {

          border-right: none;
          background-image: repeating-linear-gradient(
          to bottom,
          transparent,
          transparent 3px,
          #fff 3px,
          #fff 6px
        ),
        repeating-linear-gradient(to right, #999, #999 3px, #fff 3px, #fff 6px);
    }
  }

  // SFG
  .mask-editor__tools {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 2rem;
    bottom: 6rem;
    // width: 100%;
    // padding-right: 8px; // fab 56 - mini fab 40 / 2

    @include mobile {
      // prepping media queries
    }

    @include tablet {
      // margin-right: -64px;
    }
  }

  .mask-editor__tools > div {
    transition: all 0.1s;
    cursor: pointer;
    pointer-events: all;
    width: 40px;
    height: 40px;
    margin: 12px 0;

    &.hints-button,
    &.all,
    &.fill,
    &.stroke,
    &.dots {
      background-position: 8px 8px;
      background-size: 24px;
      background-repeat: no-repeat;
      border-radius: 100%;

      &.color-type--active {
        background-color: var(--bg-buttons-active);
      }
    }

    &.hints-button {
      background-image: url("../images/me-hints-white.svg");

      &.color-type--active {
        background-image: url("../images/me-hints-blue.svg");
      }
    }


    &.fill {
      background-image: url("../images/me-fills-v2-white.svg");

      &.color-type--active {
        background-image: url("../images/me-fills-v2-blue.svg");
      }
    }

    &.stroke {
      background-image: url("../images/me-strokes-v2-white.svg");

      &.color-type--active {
        background-image: url("../images/me-strokes-v2-blue.svg");
      }
    }

    &.dots {
      background-image: url("../images/me-dots-v2-white.svg");

      &.color-type--active {
        background-image: url("../images/me-dots-v2-blue.svg");
      }
    }

    &.all {
      background-image: url("../images/me-all-v3-white.svg");

      &.color-type--active {
        background-image: url("../images/me-all-v3-blue.svg");
      }

    }

  }

  .region-select {
    background: var(--bg-buttons);
  }

  .select-all {
    pointer-events: all;
  }

  .color-type {
    pointer-events: all;
  }
  .color-list__item {
    pointer-events: all;
  }
  .color-type__label {
    // pointer-events: none; // disable clicking the label itself, thus adding --active to the element
  }
} // #mask-editor

// footer {
//   pointer-events: none;
// }


// voice pitch slider
.voice-pitch-slider {
  position: absolute;
  bottom: 2rem;
  width: calc(100% - 4rem);

  label {

  }
  input[type=range] {
    //transform: rotateZ(270deg);
    pointer-events: all;
  }
}


// generate random masks
.generate-mask {
  position: absolute;
  bottom: 2rem;
  width: calc(100% - 4rem);
  pointer-events: all;
  cursor: pointer;
}
