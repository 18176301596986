// modals
// by default, .modal = display none.
// modal-overlay is opacity 0 & transition opacity
// js first sets model display block and add .open class to modal
// modal.open -> .modal-overlay transitions opacity.

.modal {
  // display: none;
  text-align: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  flex-flow: column nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: var(--bg-color-alpha);
  opacity: 0;
  pointer-events: none;
  transition: opacity .1s ease;
  z-index: -100;

  &.modal-visible {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    z-index: 100;
  }

  &.avatar-editor {
    background: transparent;
  }

  &.modal-visible.avatar-editor.face-editor {
    pointer-events: none;
  }

  .modal-overlay {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }


  .modal-dialogue {
    width: calc(100% - #{$headerHeight});
    box-shadow: 0 5px 12px var(--border-color);
    background: var(--bg-color);

    @include tablet {
      min-width: 520px;
      width: auto;
    }

    .modal-body {
      padding: 2rem;

      &.chat {
        padding: 1rem;
        background: var(--bg-color-alpha);
      }

    }

    // __fs modifier makes dialogue full screen
    &__fs {
      width: 100%;
      background: var(--bg-color);

      .modal-body {
        position: relative;
        min-height: calc(100vh - (#{$headerHeight} * 3));
        min-height: calc((var(--vh, 1vh) * 100) - (#{$headerHeight} * 3));
        padding: 2rem;
        max-width: 620px;
        margin: 0 auto;
        max-height: calc(100vh - (#{$headerHeight} * 3));
        max-height: calc((var(--vh, 1vh) * 100) - (#{$headerHeight} * 3));
        overflow: auto;
        // white-space: pre-line;

        &__wide {
          max-width: 100%;
          padding: 0;
        }
      }
    }
  }

  .modal-header {
    position: relative;
    height: $headerHeight;
    border-bottom: 1px solid var(--border-color);

    .modal-title {
      margin: 0 auto;
      line-height: $headerHeight;
      font-weight: 400;
      font-size: medium;
      text-transform: capitalize;
    }
  }



  .modal-subheader {
    height: $headerHeight;
    border-bottom: 1px solid var(--border-color);

      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include mobile-xl {
        display: block;
      }
    .modal-subheader-nav ul li {
      display: inline-block;
      line-height: calc(#{$headerHeight} - 2px);
      padding: 0 0.5rem;
      text-transform: uppercase;
      cursor: pointer;
      font-size: .7rem;
      @include mobile {
        font-size: .8rem;
      }
      @include mobile {
        font-size: .9rem;
      }

      @include tablet {
        font-size: 1rem;
        padding: 0 1rem;
      }

      &.active {
        border-bottom: 2px solid var(--bg-color-inv);
      }

    }

  }



  .modal-footer {
    // position: absolute;
    // bottom: 0;
    // width: 100%;
    height: $headerHeight;
    border-top: 1px solid var(--border-color);
    line-height: $headerHeight;
    text-align: right;
    padding-right: 2rem;
    padding-left: 2rem;

    &.chat {
      padding-right: 0;
    }
  }


  // tabbed subnav on modal-body
  .tab-content {
    display: none;
  }

  .tab-content.open {
    display: block;
  }
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 56px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.close-icon {
    background-image: url('../images/close.svg');
    background-repeat: no-repeat;
    background-position: center center;
  }

}

.popover-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 10px;
  height: 10px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


// ***************** //
// ** MODAL TYPES ** //
// ***************** //


// Avatar Editor is different.
// PowderCurtain must be visible, different pointer events ...
.modal.avatar-editor {
  .modal-overlay,
  .modal-dialogue__fs {
    background: transparent;
    ///pointer-events: none;
  }
  .modal-header,
  .modal-subheader,
  .modal-footer {
    background: var(--bg-color);
    pointer-events: all;
  }
}


// Room Forms
.modal form {

  .form-item {
    display: grid;
    grid-template-columns: 180px 1fr;
    align-items: start;
    text-align: left;
    grid-gap: 2rem;
  }

  .background-image {
    padding: 1rem;
    border: 1px dashed var(--border-color);
  }

  .image-thumb {
    width: 80px;
    height: 80px;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
  }


}

// explore rooms
.room_card {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 2rem;

  p {
    text-align: left;
  }

}


// WearWeAlgo promotion popover
#wear-wealgo {
  position: fixed;
  bottom: 4rem;
  left: 1rem;
  box-shadow: 0 5px 12px var(--border-color);
  background: var(--bg-color);
  padding: 1rem;
  font-size: x-small;
  max-width: calc(100% - 2rem);
  width: calc(100% - 2rem);
  opacity: 0;
  visibility: hidden;
  z-index: -100;
  transition: opacity .2s linear;

  &.show {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }


  @include tablet {
    max-width: calc(260px + 2rem);
    width: calc(260px + 2rem);
  }

  .wear-wealgo-wrapper {
    display: grid;
    grid-template-columns: 1fr 80px;

    @include tablet {
      grid-template-columns: 180px 80px;
    }

  }

  .wear-avatar-wrapper {
    position: relative;
    height: 100%;
    width: 100%;

    .wear-product {
      background-image: url('../images/merch/hoodie_slubheathersteelgrey.png');
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .wear-avatar {
      width: 18px;
      height: 22px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      left: 30px;
      top: 24px;
    }

  }

}