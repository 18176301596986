:root {
  --bg-color: #{$BgColorBase};
  --bg-color-alpha: #{$BgColorBaseAlpha};
  --bg-color-inv: #{$BgColorLightAlpha};
  --txt-color: #{$textBgColorDark};
  --txt-color-inv: #{$textBgColorLight};
  --border-color: #{$borderBgColorDark};
  --filter: invert(1);
}

// light up your face light back light
// switch sets class on body ... relevant classes follow suit
#facelight {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $bgLightGrey;
  visibility: hidden;
  opacity: 0;
  z-index: -100;
  transition: all .2s;
  pointer-events: none;
}
body.facelight_on #facelight {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}
body.facelight_on .tooltip .tooltiptext {
  background: $bgDarkGreyAlpha;
  color: $textBgColorDark;
}
body.facelight_on .title h1 {
  color: $textBgColorLight;
}
body.facelight_on .initScreen {
  background: transparent;
  color: $textBgColorLight;

    a {
      color: $textBgColorLight;
      text-decoration: underline;
    }
}

// end facelight

html {
  font-family: "Helvetica Neue", helvetica, sans-serif;
  font-weight: 200;
  letter-spacing: 0.036rem;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  background: var(--bg-color);
  color: var(--txt-color);
}


a {
  text-decoration: none;
  color: var(--txt-color);
}

[data-theme="base"] {
  --bg-color: #{$BgColorBase};
  --bg-color-alpha: #{$BgColorBaseAlpha};
  --bg-color-inv: #{$BgColorLightAlpha};
  --txt-color: #{$textBgColorDark};
  --txt-color-inv: #{$textBgColorLight};
  --border-color: #{$borderBgColorDark};
  --filter: invert(1);
}


// [data-theme="dark"] {
//    // --bg-color: #{$bgDarkGrey};
//    // --bg-color-inv: #{$bgLightGrey};
//    --bg-color-rgba: #{$bgDarkGreyAlpha};
//    --bg-buttons: #{$wealgoBlueAlpha};
//    --bg-buttons-active: #{$bgDarkGreyAlpha};
//    --bg-buttons-hover: #{$bgDarkGreyAlpha};
//    --bg-buttons-hover-text: #{$wealgoBlue};
//    --bg-room-functions: #{$wealgoBlue};
//    --bg-button-go: #{$wealgoBlueLowLight};
// }

// [data-theme="light"] {
//   // night mode
//    // --bg-color: #{$bgLightGrey};
//    // --bg-color-inv: #{$bgDarkGrey};
//    --bg-color-rgba: #{$bgLightGreyAlpha};
//    --bg-buttons: #{$wealgoBlueAlpha};
//    --bg-buttons-hover: #{$bgLightGrey};
//    --bg-buttons-hover-text: #{$wealgoBlue};
//    --bg-buttons-active: #{$bgLightGrey};
//    --bg-room-functions: #{$color-white};
//    --bg-button-go: #{$color-white};
// }

//
// [data-theme="dark"] {
//   --bg-color: #{$BgColorDark};
//   --bg-color-alpha: #{$BgColorDarkAlpha};
//   --bg-color-inv: #{$BgColorLightAlpha};
//   --txt-color: #{$textBgColorDark};
//   --txt-color-inv: #{$textBgColorLight};
//   --border-color: #{$borderBgColorDark};
//   --filter: invert(1);
// }
//
// [data-theme="light"] {
//   --bg-color: #{$BgColorLight};
//   --bg-color-alpha: #{$BgColorLightAlpha};
//   --bg-color-inv: #{$BgColorDarkAlpha};
//   --txt-color: #{$textBgColorLight};
//   --txt-color-inv: #{$textBgColorDark};
//   --border-color: #{$borderBgColorLight};
//   --filter: none;
// }
//
// [data-theme="custom"] {
//   --bg-color: #{$BgColorBase};
//   --bg-color-alpha: #{$BgColorBaseAlpha};
//   --bg-color-inv: #{$BgColorLightAlpha};
//   --txt-color: #{$textBgColorDark};
//   --txt-color-inv: #{$textBgColorLight};
//   --border-color: #{$borderBgColorDark};
//   --filter: invert(1);
// }

//
// body {
//   font-size: 1.6rem;
//   position: relative;
//   width: 100%;
//   background-color: var(--bg-color);
//   color: $wealgoBlue;
// }
//
// button.Button.BurgerSwitch.HeaderNavigationBar__switch {
//   top: 0;
// }
//
// #app {
// }
