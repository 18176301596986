// header

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  max-height: $headerHeight;
  height: $headerHeight;


  nav {

    .hamburger {
      position: absolute;
      top: 0;
      left: 0;
    }

  }

  .title {
    margin: 0 auto;
    position: fixed;
    width: 100%;
    top: 0;

    h1 {
      line-height: $headerHeight;
      font-weight: 400;
      font-size: medium;
      text-align: center;
      text-transform: capitalize;
    }


  }


  .icons-top {
    position: absolute;
    top: 0;
    right: 0;
  }



  // height: 76px;
  // width: 100%;
  // display: grid;
  // padding-top: 16px;
  // grid-template-columns: 1fr 124px;
  // position: relative;
  // z-index: 3;
  // padding-left: 16px;
  // padding-right: 16px;

  // h1 {
  //   font-size: map-get($font-sizes, h3);
  //   line-height: map-get($line-heights, plarge);
  //   display: inline-block;
  //   text-transform: capitalize;
  // }
  // h1.site-title {
  //   font-size: map-get($font-sizes, h3);
  // }
  // h2 {
  //   font-size: map-get($font-sizes, h5);
  //   line-height: map-get($line-heights, h5);
  // }
  //
  // .debug {
  //   padding-left: 24px;
  //   display: none;
  // }
  //
  // .userCounter {
  //   font-size: map-get($font-sizes, xs);
  //   padding-left: 2px;
  // }
  //
  // .masthead {
  //   text-align: left;
  //
  //   h1 {
  //     margin-left: 4rem;
  //   }
  //
  // }
  //
  // .menu {
  //   text-align: right;
  // }

}
