// SFG: avatarBox ... wil be reused across rooms?
ol.sampleMaskBox {
  position: absolute;
  top: 74px;
  padding: 12px;
  font-size: map-get($font-sizes, p);
  // background-color: var(--bg-color-rgba);
  list-style: none;
  text-align: right;
  width: 100%;


}
ol.sampleMaskBox > li{
    position: relative;
    margin-top: 6px;
    display: inline-block;
}
