// SFG: all elements to do with forms, inputs, selects, etc
.label {
  font-size: map-get($font-sizes, plarge);
}

select {
  background: var(--bg-color-inv);
  color: var(--txt-color-inv);
  border: none;
  height: 3rem;
  width: 100%;
  font-size: map-get($font-sizes, p);
  font-family: "Helvetica Neue", helvetica, sans-serif;
  font-weight: 300;
}
input[type='text'],
input[type='email'],
textarea {
  background: var(--bg-color-inv);
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
  color: var(--txt-color-inv);
  min-height: 2rem;
  text-indent: 1rem;
}


input[type='checkbox'] { // do fancy styling laterz ...
  // background-color: $bgDarkGrey;
  height: 1rem;
  width: 1rem;
  // -moz-appearance:none;
  // -webkit-appearance:none;
  // -o-appearance:none;
  // outline: 1px solid $wealgoBlueAlpha;
}
input[type='checkbox']:checked {
  background-color: $wealgoBlueAlpha;
}


input.room-enter-input {
  background-color: var(--bg-buttons);
  border: none;
  color: $bgLightGrey;
  font-family: "Helvetica Neue", helvetica, sans-serif;
  font-weight: 500;
  font-size: map-get($font-sizes, plarge);
  width: calc(100% - 6px);
  height: 30px;
  text-indent: 6px;

  @include tablet {
    width: calc(100% - 16px);
  }


  &:focus {
    outline: none;
  }

}

input::-moz-placeholder { color: var(--txt-color-inv); }
input:-ms-input-placeholder { color: var(--txt-color-inv); }
input::-ms-input-placeholder {color: var(--txt-color-inv); }
input::placeholder { color: var(--txt-color-inv); }
::placeholder { color: var(--txt-color-inv); }


input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
input:focus::placeholder { color: transparent; } /* Modern Browsers */

input.focus {
  outline: none;
}


// SFG: styling the GD checkbox ...
.color-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.check {
  height: 20px;
  width: 20px;
  position: relative;
}

.check:after {
  content: '\00D7';
  background: var(--bg-buttons);
  background-image: url('../images/done-24px.svg');
  position: absolute;
  top: 0px;
  left: 0;
  height: 16px;
  width: 16px;
  color: transparent;
  border-radius: 0;
  background-position: 0 16px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
}
.check:checked:after {
  background-color: var(--bg-buttons);
  background-position: 0 0;
}
.check:hover:after {
  border-color: $wealgoBlue;
}
