// RoomSettings

.room-settings {
  // position: fixed;
  // right: 0;
  // width: 30vw;
  // bottom: 20%;
  line-height: 4rem;
  text-align: left;
}
