.play-recording {
  z-index: 0;
}

.play-recording video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

// hack to show/hide chrome for dialogues recordings.
// we want a clean interface (only faces) because playback will
// be in room with chrome already ...
.hide_chrome, .show_chrome {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  background-color: none;
  cursor: pointer;
  color: $bgDarkGrey;
  font-size: map-get($font-sizes, xs);

  &:hover {
    background: $wealgoBlueAlpha;
  }

}

.show_chrome {
  right: 2rem;
}


.unmute_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;


  .unmute_btn {
    width: 172px;
    height: 48px;
    background-image: url("../images/unmute_blue.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--bg-color-rgba);
    padding: 16px;
    border: 1px solid $wealgoBlueAlpha;
    cursor: pointer;
  }

}
