// record message

button.start-recording {
  position: fixed;
  bottom: 6rem;
  width: 14rem;
  left: calc(100% / 2 - 15rem);
}

button.stop-recording {
  position: fixed;
  bottom: 6rem;
  width: 14rem;
  left: calc(100% / 2 + 1rem);
}

a.download-recording {
  position: fixed;
  bottom: 4rem;
  width: 14rem;
  left: calc(100% / 2 - 7rem);
}
