.avatar-overview {
  // position: fixed;
  // width: 100%;
  // z-index: 4;
  // bottom: calc((var(--vh, 1vh) * 100) - 60vh + 84px);
  // left: 0;
  // border-right: 1px solid #fff;
  // border-bottom: 1px solid #fff;
  // border-top: 1px solid #fff;
  height: calc((var(--vh, 1vh) * 100) - 168px - 2rem);

  ul.avatar-list {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    width: calc(100% - 32px);
    max-width: calc(100% - 32px);

    li {
      margin: 0 10px 10px 0;
    }

  }

  @media screen and (min-width: $breakpoint-md) {

    width: 36vw;
  }

}
