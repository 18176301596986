// dialogs

.initScreen {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: all .4s;
  background-color: var(--bg-color);
  z-index: 2;

  .initScreen_inner {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: calc(100% - 32px);
    max-width: 520px;
    margin: 0 auto;
    position: relative;
  }

  // .tagline {
  //   align-self: end;
  //
  //   h2 {
  //     text-align: center;
  //   }
  // }
  //
  // .quote {
  //   align-self: center;
  //
  //   h2 {
  //     display: inline;
  //   }
  //   span.author {
  //     color: $wealgoRed;
  //     font-size: map-get($font-sizes, plarge);
  //   }
  //
  // }

  .message {
    display: none;
  }
  .message.loading-camera { display: inherit; }

  .messaging {
    position: absolute;
    width: 100%;
    max-width: 520px;
    bottom: 56px;

    p {
      font-size: small;
      width: 100%;
      position: absolute;
      bottom: 0;
      margin-bottom: 0;
      padding: 2rem;
    }
    p.loading:before {
      opacity: 1;
    }
    .nodetect { display: none; }
    p.waiting {
      animation: showInitMessage 4s forwards;
      animation-delay: 4s;
      opacity: 1;
    }
    p.lighting {
      animation: showInitMessage 4s forwards;
      animation-delay: 8s;
      opacity: 0;
    }
    p.covered {
      animation: showInitMessage 4s forwards;
      animation-delay: 12s;
      opacity: 0;
    }
    p.glasses {
      animation: showInitMessage 4s forwards;
      animation-delay: 16s;
      opacity: 0;
    }
    p.sorry {
      animation: showSorryMessage 4s forwards;
      animation-delay: 20s;
      opacity: 0;
    }
  }

  .errorDetect {
    position: absolute;
    width: 100%;
    max-width: 520px;
    bottom: 0;
  }

  .fr-failed { // show together with sorry 'button'
    position: fixed;
    width: 100%;
    max-height: calc(100vh - 240px); // small screens
    max-width: 520px;
    text-align: left;
    top: 124px;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    padding-left: 16px;
    padding-right: 16px;
    animation: showSorryMessage 4s forwards;
    animation-delay: 20s;
    opacity: 0;

    @include tablet {
      padding-left: 0;
      padding-right: 0;
      left: calc((100vw - 520px) / 2);
    }

  }

  @keyframes hideInitMessage {
      0% {opacity: 1;}
      100% {opacity: 0;}
  }

  @keyframes showInitMessage {
      0% {opacity: 1;}
      99% {opacity: 1;}
      100% {opacity: 0;}
  }

  @keyframes showSorryMessage {
      0% {opacity: 1;}
      100% {opacity: 1;}
  }

  .js-status-message {
    // min-height: 48px;
    // height: 48px;
    // border: 1px solid $wealgoBlue;
    // line-height: 44px;
    box-shadow: 0 5px 12px var(--border-color);
  }
  .js-enter-button {
    display: none;
    cursor: pointer;
    // height: 48px;
    border: none;
    // line-height: 44px;
  }


} // .initScreen



// tooltips
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext,
.tooltip .tooltiptext.tools {
  visibility: hidden;
  opacity: 0;
  // width: 0;
  background-color: var(--bg-color-inv);
  color: var(--txt-color-inv);
  text-align: center;
  padding: 0 1rem;
  position: absolute;
  z-index: 1;
  transition: opacity .3s;
  font-weight: 300;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  font-size: small;
  white-space: pre;
  left: 50%;
  transform: translateX(-50%);
  display: table;

  @include tablet {
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
  }

  &.north {
    bottom: 4rem;
  }
  &.north-east {
    bottom: 4rem;
    left: 5rem;

    @include desktop { // hack to get mic tooltip to allign left & center
      left: 2rem;
    }

  }
  &.east {
    right: 1rem;
  }
  &.north-west {
    bottom: 4rem;
    left: 0;
  }
  &.west {
    top: 12px;
    left: -4rem;
  }
  &.west-night-mode { // outlyer for switch
    top: -3px;
    left: -4.6rem;
  }
  &.south {
    bottom: -3rem;
  }
  &.south-west {
    bottom: -3rem;
    left: 0;
  }
  &.south-east {
    bottom: -3rem;
    left: 5rem;
  }

}

.tooltip:hover .tooltiptext, .tooltip:hover .tooltiptext.tools {
  // visibility: hidden;
  // opacity: 0;

  visibility: visible;
  opacity: 1;

  // @include tablet {
  //   visibility: visible;
  //   opacity: 1;
  // }
}

.tooltip .tooltiptext.tools {
  bottom: 4px;
  left: -6rem;

  @include tablet {
    width: fit-content;
  }
}

// show microphone tooltip text on loading ... muted is now new default
.js-mute.fadeOut .tooltiptext {
  visibility: visible;
  opacity: 1;
}

// mask editor hints dialogues
.hints_container {
  width: 300px;
  position: absolute;
  top: 18vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: calc((100vw / 2 ) - 150px);
  box-shadow: 0 5px 12px var(--border-color);
  background: var(--bg-color);
  z-index: 1;

  // p {
  //   font-size: map-get($font-sizes, plarge);
  //   line-height: map-get($line-heights, h3);
  //   padding-left: 32px;
  //   padding-right: 32px;
  // }

  .hints_nav {
    height: 32px;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }

  .hints_nav a {
    display: inline-flex;
    width: 24px;
    height: 24px;
    background-color: $wealgoBlueAlpha;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 6px;
    position: relative;
    font-size: map-get($font-sizes, xs);
    color: $bgLightGrey;
  }
  .hints_nav  a:active {
    top: 1px;
  }
  .hints_nav a:focus {
    background: $wealgoBlue;
  }
}
.hints_container.hide {
  display: none;
}

.hints_slider {
  width: 300px;
  text-align: center;
  overflow: hidden;

  .hints {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  .slides::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .hints::-webkit-scrollbar-thumb {
    background: var(----bg-scrollbar);
    border-radius: 10px;
  }
  .hints::-webkit-scrollbar-track {
    background: transparent;
  }
  .hints > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 300px;
    height: 220px;
    margin-right: 50px;
    // border-radius: 10px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;


    .hint_inner {
      display: grid;
      grid-template-rows: 1fr 1fr;
      height: 100%;
      align-items: center;
      padding: 0 12px;

      .graphics {
        display: grid;
        grid-template-columns: auto 60px;
        justify-content: space-evenly;
        align-items: center;

        .faux_tooltip {
          // width: 120px;
          padding: 0 1rem;
          background: var(--bg-color-inv);
          color: var(--txt-color-inv);
          text-align: center;
          font-weight: 300;
          height: 32px;
          line-height: 32px;
          border-radius: 2px;
          font-size: small;
          white-space: pre;
          position: relative;
        }
        // .faux_tooltip:after {
        //   content: "";
        //   position: absolute;
        //   top: 11px;
        //   left: 120px;
        //   border-width: 5px;
        //   border-width: 5px;
        //   border-style: solid;
        //   border-color: var(--bg-color-inv) transparent transparent transparent;
        //   transform: rotate(-90deg);
        // }
      }

      .icon {
        width: 42px;
        height: 42px;
        filter: none;

        &.all {
          background-image: url("../images/all_v3.svg");
          background-position: 8px 10px;
          background-size: 24px;
          background-repeat: no-repeat;
          background-color: var(--bg-color-inv);
          border-radius: 50%;
        }

        &.fill, &.stroke, &.dots {
          background-position: 9px 12px;
          background-size: 22px;
          background-repeat: no-repeat;
          background-color: var(--bg-color-inv);
          border-radius: 50%;
        }


        &.fill {
          background-image: url("../images/fill_v3.svg");
        }

        &.stroke {
          background-image: url("../images/stroke_v3.svg");
        }
        &.dots {
          background-image: url("../images/dot_v3.svg");
        }
      }
    }
  }
}


.rateClose {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px 16px;
  width: 100%;

  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    label {
      padding-left: 8px;
      font-size: map-get($font-sizes, psmall);
    }
  }
  .rate_close {
    text-align: right;
  }
}

.hintsClose {
  width: 100%;
  padding: 16px 16px;

  .hints_close {
    text-align: right;

    .close_hints:hover {
      text-decoration: underline;
      cursor: pointer;
    }

  }

}

// rate modal showing when user leaves room
.rate_container {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 18vh;
  display: grid;
  grid-template-rows: 1fr 42px;
  justify-content: center;
  align-items: center;
  left: calc((100vw / 2 ) - 150px);
  background-color: var(--bg-color-rgba);
  z-index: 1;
  border: 1px solid $wealgoBlueAlpha;

  &.hide {
    display: none;
  }

  .rate_inner {
    padding: 0 16px;

    a {
      text-decoration: underline;
    }
  }

}


// nolonger detect message
.nolongerdetect {
  position: absolute;
  top: 0;
  left: 16px;
  bottom: 0;
  width: calc(100% - 32px);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  display: none;

  p {
    font-size: small;
    background-color: var(--bg-color);
    padding: 2rem;
    box-shadow: 0 5px 12px var(--border-color);
  }

}


// Room link sharing & copy link invite friends
// .share-room {
//   width: 300px;
//   height: 300px;
//   position: absolute;
//   top: 18vh;
//   display: grid;
//   grid-template-rows: 1fr 42px 42px 42px;
//   justify-content: center;
//   align-items: center;
//   left: calc((100vw / 2 ) - 150px);
//   background-color: var(--bg-color-rgba);
//   z-index: 5;
//   border: 1px solid $wealgoBlueAlpha;
//   padding-left: 16px;
//   padding-right: 16px;
//
//   &.hide {
//     display: none;
//   }
//
//   &__copied {
//     font-size: map-get($font-sizes, plarge);
//
//     &.hide {
//       visibility: hidden;
//     }
//   }
//
//   &__action {
//     display: grid;
//     grid-template-columns: 1fr 64px;
//     font-size: map-get($font-sizes, p);
//     border: 1px solid $wealgoBlueAlpha;
//     height: 42px;
//     align-items: center;
//     grid-gap: 1px;
//   }
//
//   &__btn {
//     cursor: pointer;
//     height: 32px;
//     line-height: 32px !important;
//     background: $wealgoBlueAlpha;
//     margin-right: 6px;
//     color: $bgDarkGrey;
//
//     &:hover {
//       color: $wealgoBlue;
//       background-color: $bgDarkGrey;
//     }
//
//   }
//
//   &__close {
//     text-align: right;
//     text-decoration: underline;
//     cursor: pointer;
//   }
//
//
//   input.share-room__url {
//     background-color: var(--bg-buttons);
//     border: none;
//     color: #f5f5f5;
//     font-family: "OpenSans-Light", "Raleway", Helvetica, Arial, sans-serif;
//     font-weight: 500;
//     font-size: 1rem;
//     width: calc(100% - 6px);
//     height: 32px;
//     text-indent: 6px;
//     margin-left: 6px;
//
//     &:focus {
//       outline: none;
//       border: none;
//     }
//
//   }
//
// }


//
// .ruReady {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   justify-content: center;
//   align-items: center;
//   z-index: 0;
//   display: none;
//
//
//   .loading_container {
//     width: 220px;
//     height: 1px;
//     position: relative;
//   }
//
//   .loading_bar {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 100%;
//     /* width: 10%; */
//     background-color: $wealgoBlue;
//     animation: loading linear 1.5s forwards;
//   }
//
//
//   @keyframes loading {
//     0% {width: 5%;}
//     100% {width: 100%;}
//   }
//
// }



// modals for help & map display
// .help-container {
//
// }



.help, .map {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg-color-alpha);
  z-index: 10;
  padding-top: 124px;
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
  padding-left: 16px;
  padding-right: 16px;

  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &__text {
    text-align: left;
    max-width: 520px;
    margin: 0 auto;
    height: calc(100vh - 124px - 28px); // top margin + bottom
    max-height: calc(100vh - 124px - 28px); // top margin + bottom
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    white-space: pre-line;

    &:-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

    }


    span.back {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    }


  }

}

// .help-close, .map-close, .help-show {
//   float: right;
//
//   // @include tablet {
//   //   margin-right: 16px;
//   // }
//
// }

// new help/room info close/show icon ...
// .help-close, .help-show {
//   position: fixed;
//   right: 0;
//   top: 10rem;
//   z-index: 11;
// }



// SFG: temp blur mask checkbox ... TODO: like or not and put elsewhere
.do_blur {
  position: fixed;
  top: 48px;
  right: 22px;
  z-index: 1;
  font-size: map-get($font-sizes, xs);

  label {
    margin-right: 4px;
    vertical-align: top;
  }

}


// SFG: avatarBox ... wil be reused across rooms?
ol.avatarBox {
  position: absolute;
  top: 74px;
  right: 12px;
  padding: 12px;
  font-size: map-get($font-sizes, p);
  // background-color: var(--bg-color-rgba);
  list-style: none;
  text-align: right;

  p {
    display: inline;
    font-size: map-get($font-sizes, plarge);
    font-family: monospace;
  }

}
ol.avatarBox > li{
    position: relative;
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}


// Maps & floorplans
.map__floorplan {
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

#_mapCanvas {
  width: 100%;
}
