// SFG: mask chooser for picking default or saved masks, MAX of 5

.my-masks-container {
  position: relative;
  // background: #ffffff20;
  padding: 10px;
  z-index: 1000;
  display: flex;
}

.my-masks-text {
  position: absolute;
  bottom: 220px;
  font-size: map-get($font-sizes, p);
}

.my-masks-outer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  justify-content: space-between;
  pointer-events: all;

  &::-webkit-scrollbar {
    display: none;
  }
  @include mobile-xl {
    grid-template-columns: 1fr 1fr;
  }
  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.my-mask {
  border: 1px solid var(--border-color);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transition: border 0.4s;

  &__frame {
    background-color: var(--bg-color-alpha);
    overflow: hidden;
    width: 100%;
  }
  &.selected,
  &:hover {
    border: 1px solid var(--txt-color);
    cursor: pointer;
  }

  .mask-controls {
    // background: #ffffff20;
    height: auto;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    .btn-renew,
    .btn-delete{
      height: 24px;
      width: 24px;
      opacity:.6;
      &:hover{
        opacity: 1;
      }
    }
  }
 /* &.selected {
    .mask-controls {
      .btn-renew,
      .btn-delete{
        opacity: .6;
        transition: opacity 0.2s;

        &:hover{
          opacity: 1;
        }
      }
    }
  }*/
}

.add-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  &__inner.icon-add {
    width: 100px;
    height: 100px;
    opacity: .6;
    background-size: 100px 100px ;
    cursor: pointer;
    transition: opacity 0.2s;
    &:hover{
      opacity: 1;
    }
  }
}



.renew-mask{
  height: 24px;
  width: 24px;
}
