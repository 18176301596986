#_room > canvas  {
  // border: 1px solid blue;
  position: absolute;
}

.video-grid {
  position: absolute;
  overflow:hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.video-container {
  position: absolute;
  overflow:hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #161625;
  background-color: var(--bg-color);
}

#_webcam { // SFG: sorting out the ios issues camera cant be hidden

  display: none;

  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    display: block;
  }

  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    display: block;
  }

}
