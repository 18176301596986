#radioFip {
  position: fixed;
  top: 48px;
  left: 16px;
  text-align: center;
  z-index: 4;

  @include tablet {
    left: 24px;
  }

  .creditsFip {
    font-size: map-get($font-sizes, xs);
  }
  .creditsFipMuted {
    display: none;
    font-size: map-get($font-sizes, xs);
  }

  .fip {
    color: $wealgoBlueAlpha;
    cursor: pointer;
    transition: all .1s;
    width: 24px;
    height: 24px;
    margin: 0 auto;

      span:after {
        font-family: 'Material Icons';
        content: "volume_up";
        // font-size: 24px;
      }

      span:hover:after {
        content: "volume_off";
      }

      &.muted span:after {
      font-family: 'Material Icons';
      content: "volume_off";
      // font-size: 24px;
    }

  }

}
