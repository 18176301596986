@mixin font-size($size-value: 10) {
  @if type-of($size-value) == string {
    font-size: map-get($font-sizes, $size-value);

    @media screen and (min-width: $breakpoint-lg) {
      font-size: map-get($font-sizes-lg, $size-value);
    }

    @media screen and (min-width: $breakpoint-lg) {
      font-size: map-get($font-sizes-xl, $size-value);
    }
  } @else if type-of($size-value) == number {
    font-size: ($size-value / 10) + rem;
  } @else {
    @warn "Make sure that `$size-value` is a number or a string.";
  }
}

/*
 * Hover, focus state
 */
@mixin hocus {
  &:hover,
  &:focus {
    @content;
  }
}
