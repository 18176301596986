/**
     Generates an vert rythm for the provided attributes
     input can be "margin-top" or ("margin-top", "margin-bottom") and the vr-rythm value
     for example.
  */
@mixin vr-rhythm($sAttribute, $iValue: 0) {
  @if type-of($sAttribute) == "string" {
    #{$sAttribute}: map-get($vr-sizes-xs, $iValue);

    @media screen and (min-width: $breakpoint-lg) {
      #{$sAttribute}: map-get($vr-sizes, $iValue);
    }
  } @else {
    @each $cssProperty, $iVRvalue in $sAttribute {
      //if a key has a vr value then use that
      @if type-of($iVRvalue) == "number" {
        #{$cssProperty}: map-get($vr-sizes-xs, $iVRvalue);
      } @else {
        #{$cssProperty}: map-get($vr-sizes-xs, $iValue);
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      @each $cssProperty, $iVRvalue in $sAttribute {
        //if a key has a vr value then use that
        @if type-of($iVRvalue) == "number" {
          #{$cssProperty}: map-get($vr-sizes, $iVRvalue);
        } @else {
          #{$cssProperty}: map-get($vr-sizes, $iValue);
        }
      }
    }
  }
}

/*
  * get-vr-rhythm
  * 
  * Returns the vertical rhythm for the given index.
  *
  * padding: get-vr-rhythm(1);
  */
@function get-vr-rhythm($index) {
  @return map-get($vr-sizes, $index);
}

/* get-vr-rhythm-xs
  * 
  * Returns the vertical rhythm for the given index (mobile)
  *
  * padding: get-vr-rhythm(1);
  */
@function get-vr-rhythm-xs($index) {
  @return map-get($vr-sizes-xs, $index);
}
