.nav-divider {
  border-bottom: 1px solid var(--border-color);
  margin-top: 6px;
  margin-bottom: 6px;
}

.nav-divider-vertical {
  border-left: 1px solid var(--border-color);
  margin-left: 6px;
  margin-right: 6px;
  height: 16px;
  margin-top: 20px;
}


nav.menu {
  position: fixed;
  z-index: 200;
}


.status-label--full {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  font-size: map-get($font-sizes, pxlarge);

  @media screen and (min-width: $breakpoint-md) {
    padding: 1rem;
  }
}
.status-label {
  font-size: 1rem;
  text-align: right;
  height: 24px;
  padding-right: 4px;

  &--live, &--playback  {
    color: $wealgoGreen;
    position: relative;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
    animation: pulse 3s infinite;
  }

  &--live {
    color: $wealgoRed;

  }
  &--playback {
    svg circle:first-child {
      fill: green;
      stroke: green;
    }

    svg g {
      stroke: green;
    }
  }
}


// bottom nav more-menu
nav.more-menu .more-nav-popup {
  position: absolute;
  bottom: 56px;
  background: var(--bg-color);
  box-shadow: 0 5px 12px var(--border-color);
  // background-color: var(--bg-color-inv);
  // color: var(--txt-color-inv);
  padding: 26px;
  line-height: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s linear;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding-left: 0;

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

}

.more-menu-icon:hover~.more-nav-popup,
ul.more-nav-popup:hover {
  display: block;
  visibility: visible;
  opacity: 1;
}
