// get Hoodie room
.hoodie {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-position: center center;
}

#product_svg {
  position: relative;
  // mix-blend-mode: multiply;
}

#product_path {
  fill: #f90;
}

// obsolete since redesign. now only used in wear-wealgo
.room-functions {
  height: 56px;
  pointer-events: all;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--bg-color-alpha);
  border-top: 1px solid var(--border-color);
}

// now in modals in rooms, here only used in wear-wealgo
.mask-toggle {
  position: fixed;
  bottom: 0;
  left: 30%;
  height: 56px;
  z-index: 1;
  pointer-events: none;


  .expand-more,
  .expand-less {
    float: right;
    cursor: pointer;
    // border-radius: 50%;
    pointer-events: all;
  }

  // SFG: new editor toggle
  .expand-more {
    display: block;
  }
  .expand-less {
    // display: none;
  }
}

// holding me tools: colors & elements
.me-container {
  position: fixed;
  bottom: calc(56px + 1rem);
  width: 100%;
  max-width: 620px;
  margin-left: calc((100vw - 620px) / 2);
}


// position avatar in printable area
p#position {
  position: fixed;
  bottom: calc(56px + 4rem);
  left: calc((100vw - 300px) / 2);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  color: var(--txt-color-inv);
  font-weight:  400;
}


// position icon buttons ... phht
.editor-open, .editor-close {
  position: fixed;
  left: calc(50% - 28px - 56px - 1rem);
}
.snap-avatar, .snap-cancel { // center screen
  position: fixed;
  left: calc(50% - 28px);
}
.cart {
  position: fixed;
  left: calc(50% + 28px + 1rem);
}


.merch_selectors_wrapper {
  position: fixed;
  top: 56px;
  left: -160px;
  width: 160px;
  transition: all .2s;

  @include tablet {
    left: 16px;
  }

  &.show_selectors {
    left: 16px;
  }

.merch_selectors {
  position: relative;

  label {
    height: 3rem;
    line-height: 3rem;
    font-weight: 400;
  }

  select {
    background: $BgColorBase;
    color: var(--txt-color-inv);
    border: none;
    height: 56px;
    width: 100%;
    font-size: map-get($font-sizes, p);
    font-family: "Helvetica Neue", helvetica, sans-serif;
    font-weight: 300;
  }

  & > div {
    padding-bottom: 6px;
  }

  .colors, .sizes {
    display: flex;
    justify-content: space-between;
  }

  .color {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    // border: 1px solid $wealgoBlueAlpha;
    cursor: pointer;
  }

  .size {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: $bgDarkGreyAlpha;
    // border: 1px solid $wealgoBlueAlpha;
    font-size: small;
    color: $bgLightGrey;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.selected {
      background-color: $wealgoBlue;
    }
  }

  .merch_toggle {
    position: absolute;
    top: 48px;
    padding-bottom: 0;
    right: -56px;
    background-color: $BgColorLightAlpha;

    @include tablet {
      display: none;
    }


  }

}

  .merch_info {
    padding-top: 12px;

    p {
      font-size: map-get($font-sizes, p);
    }

  }

}

#product_colors.colors {
  display: grid;
  grid-template-columns: repeat(5, auto);
  justify-content: space-between;
  grid-gap: 6px;
}


// styling the color output text
.merch_color__wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 6px;

  #merch_color_text {
    height: 3rem;
    line-height: 3rem;
    white-space: pre;
  }

}

span.snap {
  padding-right: 6px;
}

button#freeze.enter {
  background-color: var(--bg-color-inv);
  // background-image: -webkit-radial-gradient(calc(100% - 28px) calc(100% + 0px), circle farthest-corner, transparent 0, transparent 34px, #00a0ff 35px);
}

button.again {
  // width: calc(100% - 32px);
  // max-width: 520px;
  // position: fixed;
  // bottom: 106px;
  // background-image: none;
  // background-color: $wealgoRed;
}

button.hoodie {
  background-color: $wealgoBlue;
  // background-image: -webkit-radial-gradient(calc(100% - 28px) calc(100% + 0px), circle farthest-corner, transparent 0, transparent 34px, #00a0ff 35px);
}

#position.nbtn.overlay {
  cursor: default;
  opacity: 1;
  font-size: map-get($font-sizes, plarge);
}


// created small screen merch selectors toggle
// .small_screens {
//   display: none;
//
//   .center {
//     text-align: center;
//   }
//
//   .help {
//     background-color: $bgDarkGreyAlpha;
//     z-index: 100;
//     color: $bgLightGrey;
//     // padding: 8rem;
//
//     a {
//       color: $wealgoBlue;
//       text-decoration: none;
//     }
//   }
// }

// small screens don't really work here ...
// need to work on a mobile optimised version ...
// @media only screen and (max-width: 768px) {
  // .small_screens { display: block; }
// }




// REDESIGN
.me-clone {
  position: relative;
  min-height: calc(100vh - (56px * 3));
  padding: 2rem;
  max-width: 620px;
  margin: 0 auto;
  max-height: calc(100vh - (56px * 3));
  margin-top: 6rem;
}



// again trying new background product images & css overlays
#product-container {
  position: relative;

  #product-svg {
    position: relative;
    z-index: 2;
    mix-blend-mode: multiply;
  }
  #background-image {
    position: absolute;
    top: 0;
    /* width: 100%; */
    /* height: auto; */
    left: 0;
  }

  #product-shape {
    fill: #43536c;
  }

}


// make wear border grabbable
#wear-border {
  cursor: grab;

  &.dragging {
    cursor: grabbing;
  }

}

// coupon product not ok warning
.coupon-warning {
  background-color: rgba(255,0,0,.7);
  color: white;
  padding: 6px;
}
