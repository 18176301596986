// voicechat

form.voicechat {
  display: grid;
  grid-template-columns: 1fr 48px;
  justify-content: center;
  align-items: center;
}

.chat-container .chat-inner .chat-form p.chat-message {
  background: transparent;
  color: $wealgoBlue;
  font-size: map-get($font-sizes, plarge);
  padding: 0;
}

.voice-status .material-icons.mic {
  border-radius: 100%;
  color: $wealgoBlueAlpha;
  box-shadow: 0 0 0 0 rgba(90, 153, 212, 0.5);
  animation: pulse 2s infinite;
  margin-left: 12px;
  margin-right: 12px;
  transition: all .2s;

  &:hover {
    animation: none;
  }
}

.voice-status.active {
  .mic {
    color: $wealgoBlue;
    animation: none;
  }
  .sending {
    display: none;
  }
}


.voice-status.error {
  .mic {
    color: $wealgoRed;
    animation: none;
  }
  .sending {
    display: none;
  }
}

.voice-status.waiting {
  .sending {
    display: none;
  }
}

.voice-status.sending {
  .mic {
    display: none;
  }
  .sending {
    display: inline;
  }
}

.material-icons.sending {
  color: $wealgoBlueAlpha;
  font-size: 16px;
}


@keyframes pulse {
  0% {
   transform: scale(1);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 48px rgba(90, 153, 212, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
