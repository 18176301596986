// Chat

.modal.room-chat {
  background: transparent;


  @include tablet {
    width: 362px;
    right: 0;
    left: auto;
  }


  .modal-overlay {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    position: fixed;
    top: 0;
    left: auto;
    bottom: 0;
    right: -100vw;
    transition: all .2s;

    @include tablet {
      width: 362px;
      right: -362px
    }

  }

  &.modal-visible {

    .modal-overlay {
      right: 0;
    }
  }

  .modal-dialogue {
    min-width: auto;
    width: auto;
    background: transparent;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    .modal-header,
    .modal-subheader,
    .modal-footer {
      background: var(--bg-color);
    }

    .modal-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }


  }

}


.qa-container {


  .qa-inner {
    display: grid;
    grid-template-columns: 1fr 56px;
    align-items: center;
    align-content: center;
    // padding: 0 16px;
    // height: 84px;
  }

  .qa-form {
    display: flex;
    flex-direction: row;
    flex-flow: row-reverse;
    align-items: center;
  }

}


.chat-messages {
  height: calc((var(--vh, 1vh) * 100) - 168px - 2rem);
  transition: all .2s;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end; // cant use, breaks vertical scroll

  li:first-child {
    margin-top: auto !important; // push content down, dont break scroll
  }

}

ul#chat li {
  text-align: left;
  display: grid;
  grid-template-columns: 56px 1fr;
  align-items: center;
  margin-bottom: 6px;

  p {
    text-align: left;
    // font-family: monospace;
    // font-size: map-get($font-sizes, plarge);
  }

}
