// QR code in room
.qr_container {
  // position: fixed;
  // top: 6rem;
  // left: 12px;
}

.qr_code {
  width: 180px;
  margin: 0 auto;

  img {
    width: 100%;
  }

  // & svg {
    // filter: invert(0) sepia(100%) saturate(10000%) hue-rotate(1deg) brightness(125%) contrast(100%);
    // width: 100%;
    // height: 100%;
  // }

}
