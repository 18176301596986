// SFG layups below
.page-wrapper {
  // height: 100vh;
  // height: calc(var(--vh, 1vh) * 100);
  // padding-left: 16px;
  // padding-right: 16px;
  // width: 100%;
}

  // header {
  //   // height: 76px;
  //   width: 100%;
  //   display: grid;
  //   padding-top: 16px;
  //   grid-template-columns: 1fr 124px;
  //   position: relative;
  //   z-index: 3;
  //   padding-left: 16px;
  //   padding-right: 16px;
  //
  //   h1 {
  //     // font-size: map-get($font-sizes, h3);
  //     // line-height: map-get($line-heights, plarge);
  //     // display: inline-block;
  //     // text-transform: capitalize;
  //   }
  //   h1.site-title {
  //     font-size: map-get($font-sizes, h3);
  //   }
  //   h2 {
  //     font-size: map-get($font-sizes, h5);
  //     line-height: map-get($line-heights, h5);
  //   }
  //
  //   .debug {
  //     padding-left: 24px;
  //     display: none;
  //   }
  //
  //   .userCounter {
  //     font-size: map-get($font-sizes, xs);
  //     padding-left: 2px;
  //   }
  //
  //   .masthead {
  //     text-align: left;
  //
  //     h1 {
  //       margin-left: 4rem;
  //     }
  //
  //   }
  //
  //   .menu {
  //     text-align: right;
  //   }
  //
  // }

  main {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: flex-start;
    text-align: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    h1, p {
      text-align: center;
    }
  }

  // SFG: background image on _room
  #_room {
    background-position: center center;
    background-size: cover;
  }

  // SFG: animated gradient background
  // #_room {
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  // background-size: 400% 400%;
  // animation: gradient 15s ease infinite;
  // height: 100vh;

  // background: linear-gradient(188deg, #a6b41c, #1c79b4, #eeef48);
  // background-size: 600% 600%;
  // -webkit-animation: AnimationName 34s ease infinite;
  // -moz-animation: AnimationName 34s ease infinite;
  // animation: AnimationName 34s ease infinite;

  // }

  // @keyframes gradient {
  // 	0% {
  // 		background-position: 0% 50%;
  // 	}
  // 	50% {
  // 		background-position: 100% 50%;
  // 	}
  // 	100% {
  // 		background-position: 0% 50%;
  // 	}
  // }

    // @-webkit-keyframes AnimationName {
    //   0%{background-position:38% 0%}
    //   50%{background-position:63% 100%}
    //   100%{background-position:38% 0%}
    // }
    // @-moz-keyframes AnimationName {
    //     0%{background-position:38% 0%}
    //     50%{background-position:63% 100%}
    //     100%{background-position:38% 0%}
    // }
    // @keyframes AnimationName {
    //     0%{background-position:38% 0%}
    //     50%{background-position:63% 100%}
    //     100%{background-position:38% 0%}
    // }

  // ! animated gradient background


  footer {

    .bottom-nav {
      height: $headerHeight;
      position: fixed;
      bottom: 0;
      width: 100%;

      @include desktop {
        text-align: center;
      }

    }


    .edit-controls {
      position: fixed;
      bottom: 0;
      right: 0;
      height: $headerHeight;
      display: flex;
    }


    .room-extras {
      .collapsed {
        display: block;
      }
      .expanded {
        display: none;
      }
      @include tablet {
        .collapsed {
          display: none;
        }
        .expanded {
          display: block;
        }
      }
    }

  }

  #mask-editor,
  .room-enter,
  .meeting-room-title,
  .room-controls,
  #hints,
  #_room,
  #rate,
  .nolongerdetect,
  #js-room-title,
  // .share-room,
  .userCounter,
  // .my-masks-container,
  .showdoors,
  .icon,
  .golive
  {
    display: none;
  }

  // keep night mode switch on during FR...
  .icon.init {
    display: flex;
  }
  .icon.live-icon { // cant hide, collapses nav height
    display: block;
    visibility: hidden;
  }

  // hide-in initroom
  .first_detected {
    .initScreen,
    .site-title
    {
      display: none;
    }

    #mask-editor,
    .room-enter,
    .go,
    #js-room-title,
    #hints,
    #rate,
    // .my-masks-container
     {
      display: block;
    }

    // .ruReady {
    //   display: flex;
    // }

    // #my-masks {
    //   display: flex;
    // }

  }

  .in_room {
    .meeting-room-title,
    #_room,
    .room-controls,
    .icon
    {
      display: inherit;
    }

    .icon.live-icon {
      visibility: visible;
    }

    .userCounter,
    .showdoors,
    .golive {
      display: block;
    }

    .room-enter,
    .powderCurtain,
    // .my-masks-container,
    nav.icons-right,
    #facelight
    {
      display: none;
    }
  }

  .mask_editor {

    // #my-masks {
    //   display: none;
    // }

    // .my-masks-container,
    .qa_container,
    .read-chat-container,
    .avatar-overview {
      display: none;
    }

    .go,
    #_room {
    }
    .powderCurtain
    {
      display: block;
    }
  }

  .zoomed .powderCurtain
  {
    display: block;
  }

  .canvas-render {
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
  }


.powderCurtain {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  background-color: var(--bg-color);
  overflow: hidden;
  // opacity: 0;
  // transition: all 4s;
}

.mask-toggle {
  // pointer-events: all;
}
