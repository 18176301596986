//layout
$margin-1: 10px;
$margin-2: 20px;

// border
$border-radius-base: 4px;

// animation
$animSpeed: 0.25s;
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeOutQuart: cubic-bezier(0.25, 0.46, 0.45, 0.94);


$headerHeight: 56px;
