.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(auto);
  grid-gap: ($margin-1 * 2) 0;
  justify-items: stretch;
  align-items: stretch;
  padding: $margin-1;

  @media screen and (min-width: $breakpoint-sm) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $margin-1 * 2;
    padding: $margin-1 * 2;
  }
  @media screen and (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  &__block {
    padding: $margin-1 * 2;
    background-color: $color-white;
    border-radius: $border-radius-base;
    box-shadow: none !important;
    &--highlighted {
      background-color: $color-secondary;
      * {
        color: $color-white !important;
      }
    }

    p:last-child {
      margin: 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: $margin-1 * 2;
    }
  }
}
