#private_room {
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-gap: 1px;

  input[type='text'] {
    height: 48px;
    color: $bgLightGrey;
    padding-left: 12px;
  }

  input[type='submit'] {
        min-width: 64px;
        width: 100%;
        display: inline-block;
        border: none;
        margin: 0;
        height: 48px;
        text-decoration: none;
        cursor: pointer;
        background-color: var(--bg-buttons);
        color: $bgLightGrey;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-family: $body-font-family;
        font-weight: 500;
        font-size: map-get($font-sizes, plarge);

      &:focus {
          outline: 1px solid $wealgoBlue;
          outline-offset: -4px;
      }
      &:hover {
        background: $bgDarkGreyAlpha;
        color: $wealgoBlue;
      }

  }


}
