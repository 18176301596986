.door {
  position: absolute;
  opacity: .56;
  transition: all .2s;
  width: 50vw;
  height: 50vw;
  background: radial-gradient(circle 25vw at 50% 100%, $wealgoBlue, rgba(0, 0, 0, 0));


  @include desktop {
    width: 25vw;
    height: 25vw;
    background: radial-gradient(circle 12.5vw at 50% 100%, $wealgoBlue, rgba(0, 0, 0, 0));
  }


  &.e {
    transform: rotate(-90deg);
  }
  &.n {
    transform: rotate(180deg);
    // animation: liveInsideNorth 10s ease-in-out 0s alternate infinite;

    // @keyframes liveInsideNorth
    // {
    //     0%  {   }
    //     90% {  opacity: 1; }
    //     100%{   }
    // }

  }
  &.w {
    transform: rotate(90deg);
  }
  &.s {
    transform: rotate(0deg);
  }

}

.door.active {
  background: radial-gradient(circle 25vw at 50% 100%, $wealgoRed, rgba(0, 0, 0, 0));
  opacity: 1;

  @include desktop {
    background: radial-gradient(circle 12.5vw at 50% 100%, $wealgoRed, rgba(0, 0, 0, 0));
  }

}

.roomName {
  font-size: map-get($font-sizes, plarge);
  color: $bgDarkGrey;
  text-transform: capitalize;
  position: absolute;
  top: calc(50vw - 16px);
  left: 25vw;
  margin: -12px;
  opacity: 0;
  transition: all .2s;

  @include desktop {
    top: calc(25vw - 16px);
    left: 12.5vw;
    margin: -12px;
  }

}

.door.active .roomName {
  opacity: 1;
}


#door1 {
  right: 0;
  top: calc((100vh / 2) - 25vw);

  @include desktop {
    top: calc((100vh / 2) - 12.5vw);
  }

}

#door2 {
  left: calc((100vw / 2) - 25vw);
  top: 0;

  @include desktop {
    left: calc((100vw / 2) - 12.5vw);
  }

}

#door3 {
  left: 0;
  top: calc((100vh / 2) - 25vw);

  @include desktop {
    top: calc((100vh / 2) - 12.5vw);
  }

}

#door4 {
  bottom: 0;
  left: calc((100vw / 2) - 25vw);

  @include desktop {
    left: calc((100vw / 2) - 12.5vw);
  }

}
